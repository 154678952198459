<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          triggerOn: 'click',
          backgroundColor: 'rgb(26, 32, 44)',
          formatter(params) {
            const list = params[0].data.infos.licensing_list
            const idKey = Object.keys(list[0])[0]
            const nameKey = Object.keys(list[0])[2]
            const listLi = list.map(arr => `<li><a href="${idKey === 'id_license' ? 'licenciamento' : 'licenca'}/${arr[idKey]}/detalhes"> ${arr[nameKey] || 'Sem nome'}</a></li>`)
            const lisTest = JSON.stringify(listLi)
              .replaceAll('"', '')
              .replaceAll('[', '')
              .replaceAll(']', '')
              .replaceAll(',', '')

            return `<div>
                      <span style="padding-left: 12px">Total: ${params[0].data.infos.quantity}</span>
                      <ul style="padding: 0px 30px">${lisTest}</ul>
                    </div>`
          },
          enterable: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          left: 0,
        },
        grid: this.optionData.grid,
        xAxis: this.optionData.xAxis,
        yAxis: this.optionData.yAxis,
        series: this.optionData.series,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.echarts {
  width: auto;
  height: 34.1vh;
  // @media (max-width: 575px) {
  //   height: 250px;
  // };
  // @media (max-width: 768px) {
  //   height: 280px;
  // }
  // @media (max-width: 992px) {
  //   height: 300px;
  // };
  // @media (min-width: 1200px) {
  //   height: 35vh;
  // };
  // @media (min-width: 1900px) {
  //   height: 35vh;
  // };
}
</style>
