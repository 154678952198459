<template>
  <div><Home /></div>
</template>
<script>
import Home from '@/modules/home/Home.vue'

export default {
  components: {
    Home,
  },
}
</script>
