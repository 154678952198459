<template>
  <b-card>
    <slot />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style lang="scss" scoped>
.card {
  border: 0;
  border-radius:13px;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, .04);
  @media (max-width: 1024px) {
    margin-bottom: 1rem;
  };
}
</style>
