<template>
  <div>
    <div>
      <b-row class="mb-4">
        <b-col md="6">
          <DashboardCard>
            <div class="d-flex align-items-center justify-content-between">
              <span class="title-card-info">Status</span>
              <div class="d-flex title-card-buttons">
                <b-button
                  class="p-0 d-flex align-items-center justify-content-center"
                  :disabled="pageStatus === 1"
                  @click="alternatePage('left')"
                >
                  <VSIcons
                    name="ArrowLeft"
                    height="9"
                  />
                </b-button>
                <b-button
                  class="p-0 d-flex align-items-center justify-content-center"
                  :disabled="pageStatus === 2"
                  @click="alternatePage('right')"
                >
                  <VSIcons
                    name="ArrowRight"
                    height="9"
                  />
                </b-button>
              </div>
            </div>
            <div v-if="pageStatus === 1">
              <div
                class="border-bottom d-flex flex-column justify-content-between p-2"
              >
                <div>
                  <span class="title-card-info">Licenciamentos</span>
                </div>
                <div class="overflow-scroll d-flex flex-row justify-content-between">
                  <DashboardCardInfo
                    name="Instrução"
                    :value="cardDashboardInfo.licensing.instrucao"
                    :variation="cardDashboardInfo.licensing.instrucaoVariacao"
                  />

                  <div class="divider" />
                  <DashboardCardInfo
                    name="Protocolo"
                    :value="cardDashboardInfo.licensing.protocolo"
                    :variation="cardDashboardInfo.licensing.protocoloVariacao"
                  />
                  <div class="divider" />
                  <DashboardCardInfo
                    name="Em análise"
                    :value="cardDashboardInfo.licensing.emAnalise"
                    :variation="cardDashboardInfo.licensing.emAnaliseVariacao"
                  />
                  <div class="divider" />
                  <DashboardCardInfo
                    name="Concluído"
                    :value="cardDashboardInfo.licensing.concluido"
                    :variation="cardDashboardInfo.licensing.concluidoVariacao"
                  />

                </div>
              </div>
              <div
                class="d-flex flex-column justify-content-between p-2"
              >
                <div>
                  <span class="title-card-info">Licenças</span>
                </div>
                <div class="d-flex overflow-scroll flex-row justify-content-between">

                  <DashboardCardInfo
                    name="Vigentes"
                    :value="cardDashboardInfo.licensingConditions.vigentes"
                    :variation="cardDashboardInfo.licensingConditions.vigentesVariacao"
                  />
                  <div class="divider" />
                  <DashboardCardInfo
                    name="Vencidas"
                    :value="cardDashboardInfo.licensingConditions.vencidas"
                    :variation="cardDashboardInfo.licensingConditions.vencidasVariacao"
                  />
                  <div class="divider" />
                  <DashboardCardInfo
                    name="Renovação"
                    :value="cardDashboardInfo.licensingConditions.emRenovacao"
                    :variation="cardDashboardInfo.licensingConditions.emRenovacaoVariacao"
                  />

                  <div class="divider" />
                  <DashboardCardInfo
                    name="Prorrogação"
                    :value="cardDashboardInfo.licensingConditions.prorrogacao"
                    :variation="0"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="border-bottom d-flex justify-content-between p-2 overflow-scroll flex-row"
              >
                <DashboardCardInfo
                  name="Empresas"
                  :value="cardDashboardInfo.empresasAtivas"
                  :variation="cardDashboardInfo.empresasVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Empreendimentos"
                  :value="cardDashboardInfo.empreendimentosAtivos"
                  :variation="cardDashboardInfo.empreendimentosVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Orgão licenciador"
                  :value="cardDashboardInfo.orgaosLicenciadores"
                  :variation="0"
                />

              </div>
              <div
                class="d-flex justify-content-between p-2 overflow-scroll flex-row"
              >

                <DashboardCardInfo
                  name="Licenciamentos"
                  :value="cardDashboardInfo.licensing.licencasAtivas"
                  :variation="cardDashboardInfo.licensing.licencasVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Licenças"
                  :value="cardDashboardInfo.licensingConditions.licenciamentosAtivo"
                  :variation="cardDashboardInfo.licensingConditions.licenciamentosVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Gestão Resíduos"
                  :value="cardDashboardInfo.residuosGerenciados"
                  :variation="cardDashboardInfo.residuosVariacao"
                />

              </div>

            </div>
          </DashboardCard>
        </b-col>
        <b-col
          md="6"
          class="d-flex"
        >
          <DashboardCard class="w-100">
            <b-tabs>
              <b-tab title="Licenciamentos">
                <b-table
                  :items="tableLicensingData"
                  :fields="fields"
                  style="max-height: 12rem"
                  sticky-header
                >
                  <template #table-busy>
                    <b-spinner variant="primary" />
                  </template>
                  <template #cell(em_execucao)="row">
                    <b-badge
                      variant="warning"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.em_execucao / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                  <template #cell(vencido)="row">
                    <b-badge
                      variant="danger"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.vencido / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                  <template #cell(concluido)="row">
                    <b-badge
                      variant="primary"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.concluido / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                </b-table>
              </b-tab>
              <b-tab title="Licenças">
                <b-table
                  :items="tableLicenseData"
                  :fields="fieldsLicense"
                  style="max-height: 12rem"
                  sticky-header
                >
                  <template #table-busy>
                    <b-spinner variant="primary" />
                  </template>
                  <template #head(dentro_do_prazo)="data">
                    <div
                      v-b-tooltip.hover.left="'Prorrogação e Renovação dentro do prazo'"
                      class="text-center"
                    >
                      {{ data.label }}<span class="text-danger">*</span>
                    </div>
                  </template>
                  <template #head(fora_do_prazo)="data">
                    <div
                      v-b-tooltip.hover.left="'Prorrogação e Renovação fora do prazo'"
                      class="text-center"
                    >
                      {{ data.label }}<span class="text-danger">*</span>
                    </div>
                  </template>
                  <template #cell(vigente)="row">
                    <b-badge
                      variant="success"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.vigente / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                  <template #cell(vencida)="row">
                    <b-badge
                      variant="danger"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.vencida / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                  <template #cell(dentro_do_prazo)="row">
                    <b-badge
                      variant="primary"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.dentro_do_prazo / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                  <template #cell(fora_do_prazo)="row">
                    <b-badge
                      variant="warning"
                      class="p-1"
                    >
                      <span class="text-light font-13 d-flex align-items-center">
                        <VSIcons
                          name="TrendUp"
                          class="mr-1"
                          height="18"
                        />
                        {{ ((row.item.fora_do_prazo / row.item.total) * 100).toFixed(2) }}%</span>
                    </b-badge>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </DashboardCard>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-between">
        <b-col>
          <DashboardCard>
            <span class="title-card-info">Documentos</span>
            <vc-calendar
              ref="calendar"
              time-zone="America/Sao_Paulo"
              :update-layout="attrsLicensing"
              :attributes="attrsLicensing"
              :first-day-of-week="1"
              is-expanded
              :value="null"
              color="red"
              class="border-0"
              @update:to-page="fetchMonthLicensing"
            >
              <template #header-title="dado">
                <div>
                  {{ dado.title | title }}
                </div>
              </template>
              <template #day-popover="{ dayTitle, attributes }">
                <div>
                  <div class="text-xs text-gray-300 font-semibold text-center">
                    {{ dayTitle }}
                  </div>
                  <popover-row
                    v-for="attr in attributes"
                    :key="attr.key"
                    :attribute="attr"
                  >
                    <ul>
                      <li
                        v-for="{description, index, id} in attr.customData"
                        :key="index"
                      >
                        <router-link
                          v-if="description !== 'Hoje'"
                          :to="`licenciamento/${id}/detalhes`"
                        >
                          {{ description }}
                        </router-link>
                        <span v-else>{{ description }}</span>
                      </li>
                    </ul>
                  </popover-row>
                </div>
              </template>
            </vc-calendar>
          </DashboardCard>
        </b-col>
        <b-col md="5">
          <DashboardCard v-if="!apiInCallChart">
            <b-tabs>
              <template #tabs-end>
                <div class="select-year">
                  <b-form-select
                    v-model="yearDashboard"
                    :options="['2021', '2022','2023', '2024','2025', '2026','2027', '2028','2029', '2030','2031', '2032','2033', '2034','2035']"
                    @change="fetchColumDashboard(yearDashboard)"
                  />
                </div>
              </template>
              <b-tab title="Licenciamentos">
                <ColumnChart :option-data="option" />
              </b-tab>
              <b-tab
                title="Licenças"
              >
                <ColumnChart :option-data="optionLicense" />
              </b-tab>
            </b-tabs>
          </DashboardCard>
          <DashboardCard
            v-else
            class="d-flex align-items-center h-100"
          >
            <b-spinner
              variant="primary"
              class="d-flex justify-content-center align-items-center"
            />
          </DashboardCard>
        </b-col>
        <b-col>
          <DashboardCard>
            <span class="title-card-info">Condicionantes</span>
            <vc-calendar
              ref="calendar"
              time-zone="America/Sao_Paulo"
              :update-layout="attrsLicense"
              :attributes="attrsLicense"
              :first-day-of-week="1"
              is-expanded
              :value="null"
              color="red"
              class="border-0"
              @update:to-page="fetchMonthLicense"
            >
              <template #header-title="dado">
                <div>
                  {{ dado.title | title }}
                </div>
              </template>
              <template #day-popover="{ dayTitle, attributes }">
                <div>
                  <div class="text-xs text-gray-300 font-semibold text-center">
                    {{ dayTitle }}
                  </div>
                  <popover-row
                    v-for="attr in attributes"
                    :key="attr.key"
                    :attribute="attr"
                  >
                    <ul>
                      <li
                        v-for="{description, index, id} in attr.customData"
                        :key="index"
                      >
                        <router-link :to="`licenca/${id}/detalhes`">
                          {{ description }}
                        </router-link>
                      </li>
                    </ul>
                  </popover-row>
                </div>
              </template>
            </vc-calendar>
          </DashboardCard>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BTable,
  BSpinner,
  BBadge,
  BFormSelect,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { tableConfig } from '@core/mixins/ui/forms'
import PopoverRow from 'v-calendar/lib/components/popover-row.umd.min'
import moment from 'moment'
import { title } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import DashboardCard from '../DashboardCard.vue'
import DashboardCardInfo from '../DashboardCardInfo.vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import ColumnChart from '@/components/dashboard-charts/ColumnChart.vue'
import dashboard from '@/services/dashboard'
import licensing from '@/services/licensing'
import license from '@/services/license'

export default {
  components: {
    DashboardCard,
    DashboardCardInfo,
    BRow,
    BCol,
    BTabs,
    BTab,
    VSIcons,
    BTable,
    BSpinner,
    BButton,
    // BFormCheckbox,
    BBadge,
    BFormSelect,
    ColumnChart,
    PopoverRow,
  },
  filters: {
    title,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      pageStatus: 1,
      apiInCallChart: false,
      dataDashboardPerYear: [],
      yearDashboard: '',
      calendarInfosLicense: [],
      calendarInfosLicensing: [],
      cardDashboardInfo: {},
      option: {
        xAxis: [
          {
            type: 'category',
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            type: 'bar',
            data: [],
            splitLine: { show: false },
          },
        ],
      },
      optionLicense: {
        xAxis: [
          {
            type: 'category',
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            type: 'bar',
            data: [],
            splitLine: { show: false },
          },
        ],
      },
      attrsLicensing: [
        {
          key: 'today',
          customData: [{ description: 'Hoje' }],
          popover: {
            visibility: 'hover',
            hideIndicator: true,
          },
          highlight: {
            contentClass: 'text-primary font-weight-bold',
          },
          dates: new Date(),
        },
      ],
      attrsLicense: [
        {
          key: 'today',
          customData: [{ description: 'Hoje' }],
          popover: {
            visibility: 'hover',
            hideIndicator: true,
          },
          highlight: {
            contentClass: 'text-primary font-weight-bold',
          },
          dates: new Date(),
        },
      ],
      fields: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle',
        },
        {
          label: 'Gestor',
          key: 'manager',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Em execução',
          key: 'em_execucao',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Em atraso',
          key: 'vencido',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Concluído',
          key: 'concluido',
          sortable: true,
          tdClass: 'align-middle',
        },
      ],
      fieldsLicense: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          label: 'Gestor',
          key: 'manager',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          label: 'Vigente',
          key: 'Vigente',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          label: 'Vencida',
          key: 'vencida',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          label: 'No Prazo',
          key: 'dentro_do_prazo',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
        {
          label: 'Fora do Prazo',
          key: 'fora_do_prazo',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'align-middle',
        },
      ],
      tableLicensingData: [],
      tableLicenseData: [],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
  },
  created() {
    const year = new Date().getFullYear()
    this.fetchColumDashboard(year)
    this.fetchTableDashboard()
    this.fetchCardDashboard()
  },
  mounted() {
    const month = new Date().getMonth()
    const year = new Date().getFullYear()
    this.yearDashboard = year
    this.fetchMonthLicense({ month, year })
    this.fetchMonthLicensing({ month, year })
  },
  methods: {
    alternatePage(direction) {
      if (direction === 'right' && this.pageStatus !== 2) {
        this.pageStatus = 2
      } else {
        this.pageStatus = 1
      }
    },
    fetchCardDashboard() {
      dashboard.getAccountInfo().then(res => { this.cardDashboardInfo = res.data })
      console.log(this.res.data)
    },
    fetchColumDashboard(year) {
      this.fetchLicensingYear(year)
      this.fetchLicenseYear(year)
    },
    fetchTableDashboard() {
      this.fetchLicensingTable()
      this.fetchLicenseTable()
    },
    fetchLicensingTable() {
      licensing.getLicensingDashboardTable().then(res => {
        this.tableLicensingData = res.data
      })
    },
    fetchLicenseTable() {
      license.getLicensingConditionsDashboardTable().then(res => {
        this.tableLicenseData = res.data
      })
    },
    fetchLicensingYear(year) {
      this.apiInCallChart = true
      licensing.getLicensingDashboardPerYear(year).then(res => {
        this.option.series[0].data = res.data.map(arr => ({ value: arr.quantity, infos: arr }))
        this.apiInCallChart = false
      })
    },
    fetchLicenseYear(year) {
      this.apiInCallChart = true
      license.getLicensingConditionsDashboardPerYear(year).then(res => {
        this.optionLicense.series[0].data = res.data.map(arr => ({ value: arr.quantity, infos: arr }))
        this.apiInCallChart = false
      })
    },
    fetchMonthLicensing(page) {
      licensing.getLicensingDashboardPerMonth({ month: `${page.month < 10 ? `0${page.month}` : page.month}`, year: page.year }).then(res => {
        this.calendarInfosLicensing = res.data
        this.orgnizeDataLicensing('calendarInfosLicensing')
      })
    },
    fetchMonthLicense(page) {
      license.getLicensingConditionsDashboardPerMonth({ month: `${page.month < 10 ? `0${page.month}` : page.month}`, year: page.year }).then(res => {
        this.calendarInfosLicense = res.data
        this.orgnizeDataLicensing('calendarInfosLicense')
      })
    },
    orgnizeDataLicensing(type) {
      const isLicensing = type === 'calendarInfosLicensing'
      const unique = [...new Set(this[type].map(item => ({ date: isLicensing ? item.doc_conclusion_date : item.constraint_conclusion_date })))]
      unique.forEach(element => {
        this[isLicensing ? 'attrsLicensing' : 'attrsLicense'].push(
          {
            key: 'activy',
            customData: this[type].filter(item => item[isLicensing ? 'doc_conclusion_date' : 'constraint_conclusion_date'] === element.date).map(item => ({ description: isLicensing ? item.doc_title : item.constraint_title, id: isLicensing ? item.id_licensing : item.id_license })),
            popover: {
              visibility: 'hover',
              hideIndicator: true,
            },
            highlight: {
              style: { borderRadius: '3px' },
              color: 'red',
              fillMode: 'solid',
              contentClass: 'text-light font-weight-bold',
            },
            dates: moment(element.date).format(),
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.overflow-scroll{
  overflow: scroll;
}
.dashcard {
  margin: 10px 0 0 0;
  &-title {
    font-size: 13px;
    color: #7D8E9E;
  }
  &-info {
    font-size: 21px;
    color: #33383C;
    font-weight: bold;
  }
  &-percent {
    font-weight: 500;
    font-size: 13px;
    color: #7D8E9E;
  }
}

.divider {
  min-height: 70%;
  width: 1px;
  background-color: rgba(160, 174, 185, 0.21);
}

.select-year {
  position: absolute;
  right: 1rem;
  select {
    max-height: 35px;
  }
}

.title-card {
  &-info {
    font-size: 13px;
    font-weight: 600;
    color: #7D8E9E;
  }
  &-buttons {
    button {
      background-color: rgba(160, 174, 185, 0.13);
      height: 22px;
      width: 22px;
      border: none;
      border-radius: 100px;
      padding: 1px;
      margin-left: .3rem;
    }
  }
}
</style>
