<template>
  <div class="dashcard">
    <h6 class="dashcard-title">
      {{ name }}
    </h6>
    <span class="dashcard-info">{{ value }}</span>
    <p class="dashcard-percent d-flex align-items-center">
      <VSIcons
        :name="Number(variation) === 0 ? 'TrendNoFill' : variation > 0 ? 'TrendUpFillGrad' : 'TrendDownFillGrad'"
        class="mr-1"
        height="24"
      />{{ Number(variation).toFixed(2) || Number(0).toFixed(2) }}%
    </p>
  </div>
</template>

<script>
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    VSIcons,
  },
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    variation: {
      type: [String, Number],
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.dashcard {
  margin: 10px 0 0 0;
  max-width: 10rem;
  min-width: 10rem;
  &-title {
    font-size: 13px;
    color: #7D8E9E;
  }
  &-info {
    font-size: 21px;
    color: #33383C;
    font-weight: bold;
  }
  &-percent {
    font-weight: 500;
    font-size: 13px;
    color: #7D8E9E;
  }
}

</style>
