<template>
  <div>
    <b-row>
      <b-col>
        <DashboardCard>
          <b-tabs>
            <b-tab
              title="Comercial"
            >
              <div class="border-bottom d-flex justify-content-between p-2">
                <DashboardCardInfo
                  name="Clientes Ativos"
                  :value="comercialDashboard.activeAccounts"
                  :variation="comercialDashboard.activeAccountsVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Clientes Inativos"
                  :value="comercialDashboard.inactiveAccounts"
                  :variation="comercialDashboard.inactiveAccountsVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Clientes em RJ"
                  :value="comercialDashboard.accountsByStates ? comercialDashboard.accountsByStates.RJ : 0"
                  :variation="comercialDashboard.accountsByStatesVariacao ? comercialDashboard.accountsByStatesVariacao.RJ : 0"
                />
              </div>
              <div class="d-flex justify-content-between  p-2">
                <DashboardCardInfo
                  name="Clientes em MG"
                  :value="comercialDashboard.accountsByStates ? comercialDashboard.accountsByStates.MG : 0"
                  :variation="comercialDashboard.accountsByStatesVariacao ? comercialDashboard.accountsByStatesVariacao.MG : 0"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Clientes em SP"
                  :value="comercialDashboard.accountsByStates ? comercialDashboard.accountsByStates.SP : 0"
                  :variation="comercialDashboard.accountsByStatesVariacao ? comercialDashboard.accountsByStatesVariacao.SP : 0"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Clientes em RS"
                  :value="comercialDashboard.accountsByStates ? comercialDashboard.accountsByStates.RS : 0"
                  :variation="comercialDashboard.accountsByStatesVariacao ? comercialDashboard.accountsByStatesVariacao.RS : 0"
                />
              </div>
            </b-tab>
          </b-tabs>
        </DashboardCard>
      </b-col>
      <b-col>
        <DashboardCard>
          <b-tabs>
            <b-tab
              title="Suporte"
            >
              <div class="border-bottom d-flex justify-content-between  p-2">
                <DashboardCardInfo
                  name="Total de tickets"
                  :value="ticketsDashboard.totalTickets"
                  :variation="ticketsDashboard.totalTicketsVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Média de reposta"
                  :value="ticketsDashboard.mediaTempoPrimeiraRespostaEmHoras"
                  :variation="ticketsDashboard.mediaTempoPrimeiraRespostaEmHorasVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Ranking de satisfação"
                  :data="mockData.suporte.rankingSatisfacao"
                />
              </div>
              <div class="d-flex justify-content-between  p-2">
                <DashboardCardInfo
                  name="Em aberto"
                  :value="ticketsDashboard.totalTicketsAbertos"
                  :variation="ticketsDashboard.totalTicketsAbertosVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Resolvido"
                  :value="ticketsDashboard.totalTicketsResolvidos"
                  :variation="ticketsDashboard.totalTicketsResolvidosVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Fechado"
                  :value="ticketsDashboard.totalTicketsFechados"
                  :variation="ticketsDashboard.totalTicketsFechadosVariacao"
                />
              </div>
            </b-tab>
            <b-tab
              title="Feedback"
            >
              <div class="border-bottom d-flex justify-content-between  p-2">
                <DashboardCardInfo
                  name="Total de feedback"
                  :value="feedbackDashboard.feedbackTotal"
                  :variation="feedbackDashboard.feedbackVariacao"
                />
                <div class="divider" />
                <DashboardCardInfo
                  name="Ranking de satisficação"
                  :value="feedbackDashboard.taxaDeAprovacao"
                  :variation="feedbackDashboard.taxaDeAprovacaoVariacao"
                />
              </div>
            </b-tab>
          </b-tabs>
        </DashboardCard>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <DashboardCard>
          <div class="d-flex align-items-center justify-content-between">
            <span class="title-card-info">Financeiro</span>
            <div class="title-card-buttons">
              <b-button
                :disabled="pageFinanceiro === 1"
                @click="alternatePage('left')"
              >
                <VSIcons name="ArrowLeft" />
              </b-button>
              <b-button
                :disabled="pageFinanceiro === 2"
                @click="alternatePage('right')"
              >
                <VSIcons name="ArrowRight" />
              </b-button>
            </div>
          </div>
          <div
            v-if="pageFinanceiro === 2"
            class="border-bottom d-flex justify-content-between  p-2"
          >
            <DashboardCardInfo
              name="Total boletos vencidos"
              :value="paymentDashboard.totalBoletosVencidos"
              :variation="paymentDashboard.totalBoletosVencidosVariacao"
            />
            <div class="divider" />
            <DashboardCardInfo
              name="Inadimplência"
              :value="paymentDashboard.totalContasInadimplentes"
              :variation="0"
            />
          </div>
          <div v-else>
            <div class="border-bottom d-flex justify-content-between  p-2">
              <DashboardCardInfo
                name="Receita total"
                :value="paymentDashboard.receitaTotal"
                :variation="paymentDashboard.receitaTotalVariacao"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Receita por plano"
                :value="paymentDashboard.receitaTotal"
                :variation="paymentDashboard.receitaTotalVariacao"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Receita futura"
                :value="paymentDashboard.receitaMensalFutura"
                :variation="0"
              />
            </div>
            <div class="d-flex justify-content-between  p-2">
              <DashboardCardInfo
                name="Receita plano Basic"
                :value="paymentDashboard.receitaPorPlano.Basic"
                :variation="paymentDashboard.receitaPorPlanoVariacao.Basic"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Receita plano Standart"
                :value="paymentDashboard.receitaPorPlano.Standart"
                :variation="paymentDashboard.receitaPorPlanoVariacao.Standart"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Receita plano Premium"
                :value="paymentDashboard.receitaPorPlano.Premium"
                :variation="paymentDashboard.receitaPorPlanoVariacao.Premium"
              />
            </div>
          </div>
        </DashboardCard>
      </b-col>
      <b-col>
        <DashboardCard>
          <div class="min-height d-flex align-items-center justify-content-between">
            <span class="title-card-info">Consumo operacional</span>
          </div>
          <div>
            <!-- operationalDashboard -->
            <div class="border-bottom d-flex justify-content-between  p-2">
              <DashboardCardInfo
                name="Empresas"
                :value="operationalDashboard.company_qtd"
                :variation="0"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Empreendimentos"
                :value="operationalDashboard.project_qtd"
                :variation="0"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Licenciamentos"
                :value="operationalDashboard.licensing_qtd"
                :variation="0"
              />
            </div>
            <div class="d-flex justify-content-between  p-2">
              <DashboardCardInfo
                name="Licenças"
                :value="operationalDashboard.license_qtd"
                :variation="0"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Op. de resíduos"
                :value="operationalDashboard.waste_operation_qtd"
                :variation="0"
              />
              <div class="divider" />
              <DashboardCardInfo
                name="Úsuarios"
                :value="operationalDashboard.user_qtd"
                :variation="0"
              />
            </div>
          </div>
        </DashboardCard>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow, BCol, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import DashboardCard from '../DashboardCard.vue'
import DashboardCardInfo from '../DashboardCardInfo.vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import accounts from '@/services/accounts'
import tickets from '@/services/tickets'
import feedback from '@/services/feedback'

export default {
  components: {
    DashboardCard, BRow, BCol, BTabs, BTab, DashboardCardInfo, BButton, VSIcons,
  },
  data() {
    return {
      pageFinanceiro: 1,
      comercialDashboard: {},
      ticketsDashboard: {},
      feedbackDashboard: {},
      paymentDashboard: {},
      operationalDashboard: {},
      mockData: {
        comercial: {
          clientesAtivos: {
            value: 200,
            variacao: -30,
          },
          clientesInativos: {
            value: 3,
            variacao: -10,
          },
          clientesEmRj: {
            value: 122,
            variacao: 30,
          },
          clientesEmSp: {
            value: 33,
            variacao: 0,
          },
          clientesEmMg: {
            value: 13,
            variacao: -13,
          },
          clientesEmRs: {
            value: 10,
            variacao: -33,
          },
        },
        suporte: {
          tickets: {
            value: 22,
            variacao: 0,
          },
          mediaDeResposta: {
            value: '2 horas',
            variacao: 30,
          },
          rankingSatisfacao: {
            value: '80%',
            variacao: 30,
          },
          emAberto: {
            value: 5,
            variacao: 30,
          },
          resolvido: {
            value: 80,
            variacao: 30,
          },
          fechado: {
            value: 4,
            variacao: 30,
          },
        },
        feedback: {
          totalFeedback: {
            value: 144,
            variacao: 30,
          },
          rankingSatisfacao: {
            value: '80%',
            variacao: 30,
          },
        },
      },
    }
  },
  created() {
    this.fetchAccountsDashboard()
    this.fetchTicketsDashboard()
    this.fetchFeedbackDashboard()
    this.fetchPaymentDashboard()
    this.fetchAccountInfoDashboard()
  },
  methods: {
    alternatePage(direction) {
      if (direction === 'right' && this.pageFinanceiro !== 2) {
        this.pageFinanceiro = 2
      } else {
        this.pageFinanceiro = 1
      }
    },
    fetchAccountsDashboard() {
      accounts.getAccountDashboardMaster().then(res => { this.comercialDashboard = res.data })
    },
    fetchTicketsDashboard() {
      tickets.getTicketDashboardMaster().then(res => { this.ticketsDashboard = res.data })
    },
    fetchFeedbackDashboard() {
      feedback.getFeedbackDashboardMaster().then(res => { this.feedbackDashboard = res.data })
    },
    fetchPaymentDashboard() {
      accounts.getPaymentsDashboardMaster().then(res => { this.paymentDashboard = res.data })
    },
    fetchAccountInfoDashboard() {
      accounts.getAccountInfoDashboard().then(res => { this.operationalDashboard = res.data })
    },
  },
}
</script>
<style lang="scss" scoped>
.divider {
  min-height: 70%;
  width: 1px;
  background-color: rgba(160, 174, 185, 0.21);
}

.min-height {
  min-height: 25px;
}

.title-card {
  &-info {
    font-size: 13px;
    font-weight: 600;
    color: #7D8E9E;
  }
  &-buttons {
    button {
      background-color: rgba(160, 174, 185, 0.13);
      height: 22px;
      width: 22px;
      border: none;
      border-radius: 100px;
      padding: 1px;
      margin-left: .3rem;
    }
  }
}
</style>
