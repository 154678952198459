<template>
  <div>
    <DashboardUserAdm v-if="getUserPermissions.name === 'Administrador'" />
    <DashboardMaster v-else-if="getUserPermissions.name === 'Master'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardUserAdm from './administrator/DashboardUserAdm.vue'
import DashboardMaster from './master/DashboardMaster.vue'

export default {
  components: {
    DashboardUserAdm,
    DashboardMaster,
  },
  computed: {
    ...mapGetters({ getUserPermissions: 'auth/getUserPermissions' }),
  },
}
</script>
